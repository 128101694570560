import { Alert, Box, Stack } from "@mui/material";
import React from "react";
import noData from "../assets/noData.png";

export const NoData = ({ isEmpty }) => {

  return (
    <React.Fragment>
      {isEmpty && (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Box>
            <img
              alt="logo"
              src={noData}
              style={{
                display: "inline-block",
                width: 300,
              }}
            />
          </Box>
          <Alert sx={{ width: 300 }} severity="error">
            Nenhum registro encontrado!
          </Alert>
        </Stack>
      )}
    </React.Fragment>
  );
};
