import * as React from "react";
import Popover from "@mui/material/Popover";
import {
  Chip,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

export default function PopoverInfoAssistido({ infoAssistida }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Chip
        size="small"
        icon={<RemoveRedEyeIcon />}
        label={infoAssistida ? infoAssistida.assistidas.length : 0}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <List dense={false}>
          {infoAssistida && infoAssistida.assistidas.map((current) => (
            <ListItem key={current}>
              <ListItemText
                secondary={current.userID.nome}
              />
              <LinearProgress sx={{ml: 2, width: 90}} variant="determinate" value={100} color="success"/>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
}
