import { Stack } from "@mui/material";
import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import usePlataforma from "../hooks/usePlataforma";
import { toast } from "react-toastify";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const SelectMatricula = ({ userID, setOpen, close, matriculado, todosCursos }) => {
    const { matricularAluno } = usePlataforma()
    const selecionados = matriculado.map((current) => current._id)
    const [cursosMatriculado, SetcursosMatriculado] = React.useState(selecionados);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        SetcursosMatriculado(typeof value === "string" ? value.split(",") : value);
        
    };

    const fechar = async () => {
        await matricularAluno(userID, cursosMatriculado)
        setOpen(close)
        toast.success("Aluno matriculado com sucesso!")
    }

    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
        >
            <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel id="demo-multiple-checkbox-label">Selecione uma opção</InputLabel>
                <Select
                    onClose={fechar}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={cursosMatriculado}
                    onChange={handleChange}
                    input={<OutlinedInput label="Selecione uma opção" />}
                    renderValue={(selected) =>
                        selected
                            .map((name) => {
                                const selectedName = todosCursos.find(
                                    (item) => item._id === name
                                );
                                return selectedName ? selectedName.nome : "";
                            })
                            .join(", ")
                    }
                    MenuProps={MenuProps}
                >
                    {todosCursos.map((item) => (
                        <MenuItem key={item._id} value={item._id}>
                            <Checkbox checked={cursosMatriculado.indexOf(item._id) > -1} />
                            <ListItemText primary={item.nome} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

        </Stack>
    );
};
