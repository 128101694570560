import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useJwt } from "react-jwt";
import { Login } from "../pages/Login";
import { Cursos } from "../pages/Cursos";
import { Modulos } from "../pages/Modulos";
import { Aulas } from "../pages/Aulas";
import { VerAula } from "../pages/VerAula";
import useToken from "../providers/useToken";
import useStore from "../providers/useStore";
import api from "../service/api";
import { PageLoading } from "../pages/PageLoading";
import { AddCurso } from "../pages/forms/AddCurso";
import { AddModulo } from "../pages/forms/AddModulo";
import { AddAula } from "../pages/forms/AddAula";
import { Usuarios } from "../pages/Usuarios";
import { AddUsuario } from "../pages/forms/AddUsuario";
import { Nota } from "../pages/Nota";
import { VerQuiz } from "../pages/VerQuiz";
import { AddQuestionario } from "../pages/forms/AddQuestionario";


const routesAdmin = [
  { path: "/adicionar/curso", component: <AddCurso /> },
  { path: "/adicionar/modulo", component: <AddModulo /> },
  { path: "/adicionar/aula", component: <AddAula /> },
  { path: "/adicionar/usuario", component: <AddUsuario /> },
  { path: "/adicionar/questionario", component: <AddQuestionario /> },
  { path: "/usuarios", component: <Usuarios /> }
];

export function Rotas() {
  const token = useToken((state) => state.token);
  const infoToken = useStore((state) => state.infoToken);
  const [isLoading, setIsLoading] = useState(!!token);
  const setInfoToken = useStore((state) => state.setInfoToken);
  const { decodedToken } = useJwt(token);

  useEffect(() => {
    if (decodedToken) {
      setInfoToken(decodedToken);
    }
  }, [decodedToken]);

  useEffect(() => {
    if (token) {
      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      setIsLoading(false);
    }
  }, [token]);

  return (
    <React.Fragment>
      {isLoading && token ? (
        <PageLoading />
      ) : (
        <BrowserRouter>
          {token ? (
            <Routes>
              <Route path="/" element={<Cursos redirect={false} />} />
              <Route path="/modulo/:id" element={<Modulos />} />
              <Route path="/videos/:id" element={<Aulas />} />
              <Route path="/video/:id" element={<VerAula />} />
              <Route path="/questionario/:id" element={<VerQuiz />} />
              <Route path="/nota/:id" element={<Nota />} />
              <Route path="/nota" element={<Nota />} />
              {infoToken.isAdmin &&
                routesAdmin.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.component}
                  />
                ))}
              <Route path="*" element={<Cursos redirect={true} />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/login" element={<Login redirect={false} />} />
              <Route path="*" element={<Login redirect={true} />} />
            </Routes>
          )}
        </BrowserRouter>
      )}
    </React.Fragment>
  );
}
