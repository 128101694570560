import create from 'zustand'
import { persist } from 'zustand/middleware'

const useToken = create(
  persist(
    (set, get) => ({
      token: null,
      setToken: (token) =>  set({ token }),
      removeToken: () => set({ token: null }),
    }),
    {
      name: 'useToken'
    }
  )
)

export default useToken