import { useQuery } from "react-query";
import api from "../../service/api";

async function getInfoVideo(ctx) {
  const [, aulaID, path ] = ctx.queryKey
  const { data } = await api.get(`/${path}`, { params: { aulaID } })
  return data
}

export default function useFetchGetInfoVideo(aulaID, path) {
    return useQuery(['infoVideo', aulaID, path], getInfoVideo, { refetchOnWindowFocus: false})
}