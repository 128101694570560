import { Box, Typography } from "@mui/material";

export const Title = ({title, strong}) => {
    return (
        <Box>
            <Typography variant='h5' noWrap component="div">
                {title} <strong>{strong}</strong>
            </Typography>
        </Box>
    );
}
