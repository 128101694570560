import { useQuery } from "react-query";
import api from "../../service/api";

async function getCurso() {
  const { data } = await api.get('/curso')
  return data
}

export default function useFetchGetCurso() {
    return useQuery(['curso'], getCurso, { refetchOnWindowFocus: false})
}