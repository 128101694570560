import create from 'zustand'

const useStore = create((set) => ({
  infoToken: {
    _id: null,
    nome: null,
    email: null,
    isAdmin: null
  },
  setInfoToken: (infoToken) => set({ infoToken })
}))

export default useStore