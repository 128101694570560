
import * as React from 'react';
import { NavBar } from '../components/NavBar';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Grid, Stack, Typography } from '@mui/material';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import capa from "../assets/capa.png"
import { Title } from '../components/Title';
import { useNavigate } from 'react-router-dom';
import useFetchGetCurso from '../queries/curso';
import { Loading } from '../components/Loading';
import { NoData } from '../components/NoData';
import AddIcon from '@mui/icons-material/Add';
import useStore from '../providers/useStore';
import usePlataforma from '../hooks/usePlataforma';

export const Cursos = ({ redirect }) => {
  const { apagarCurso } = usePlataforma()
  const infoToken = useStore((state) => state.infoToken)
  let navigate = useNavigate();

  React.useEffect(() => {
    if (redirect) {
      navigate("/", { replace: true });
    }
  }, [redirect, navigate]);

  const goModulo = (id, nome) => {
    navigate(`/modulo/${id}`);
  }

  const novoCurso = () => {
    navigate("/adicionar/curso");
  }

  const { isLoading, data } = useFetchGetCurso()

  return (
    <Box >
      <NavBar />
      
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ mt: 2, px: 3 }}
      >
      <Title title="Lista de" strong="Cursos" />
      { infoToken.isAdmin && <Button onClick={novoCurso} variant="outlined" startIcon={<AddIcon />}> Novo </Button> }
      </Stack>

      <Loading isLoading={isLoading} />
      <NoData isEmpty={!isLoading && data && data.cursos.length === 0} />
      <Grid container spacing={3} sx={{ p: 2 }}>
        {data && data.cursos.length > 0 && data.cursos.map(current => (
          <Grid key={current._id} item xl={3} lg={3} sm={4} xs={12}>
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                alt="capa"
                height="140"
                image={capa}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {current.nome}
                </Typography>
              </CardContent>
              <CardActions sx={{ flexDirection: 'column' }}>
                <Button onClick={() => goModulo(current._id)} endIcon={<VideoLibraryIcon />} fullWidth variant='contained' color='error' size="medium">VER MÓDULOS</Button>
                { infoToken.isAdmin && <Button sx={{mt: 1}} onClick={() => apagarCurso(current._id)} fullWidth variant='text' color='error' size="small">Apagar</Button> }
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
