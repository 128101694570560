import React from "react";
import { Box, Button, Card, CardContent, CardHeader, Chip, Divider, Stack, Typography, useMediaQuery } from "@mui/material";
import { NavBar } from "../../components/NavBar";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "../../components/Input";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import usePlataforma from "../../hooks/usePlataforma";

export const AddAula = () => {
    const { saveAula } = usePlataforma()
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [loading, setLoading] = React.useState(false);
    const location = useLocation();
    let navigate = useNavigate();

    const { state } = location;

    function voltar() {
        navigate(-1);
    }

    const formik = useFormik({
        initialValues: {
            nome: "",
            descricao: "",
            link: "",
            moduloID: state.moduloID,
        },
        validationSchema: Yup.object({
            nome: Yup.string().required('Esse campo é obrigatório!'),
            descricao: Yup.string().required('Esse campo é obrigatório!')
        }),
        onSubmit: async (values) => {
            setLoading(true)
            const id = toast.loading("Carregando...");
            try {
                await saveAula(values)
                setLoading(false)
                toast.update(id, {
                    render: "Registro salvo!",
                    type: "success",
                    isLoading: false,
                    autoClose: true,
                    closeButton: true,
                });
                navigate(-1);
            } catch (error) {
                setLoading(false)
                toast.update(id, {
                    render: error.response.data.message,
                    type: "error",
                    isLoading: false,
                    autoClose: true,
                    closeButton: true,
                });
            }
        },
    });


    return (
        <Box>
            <NavBar />
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ width: "100%" }}
            >
                <Typography sx={{ mb: 0.5, mt: 3 }} color="text.primary" variant="button">
                    Criar Aula
                </Typography>
                <form onSubmit={formik.handleSubmit}>
                    <Card sx={{ width: isSmallScreen ? 350 : 500 }}>
                        <CardContent>
                            <Input formik={formik} label="Nome" id="nome" />
                            <Input formik={formik} label="Descrição" id="descricao" />
                            <Input formik={formik} label="Youtube Vídeo ID" id="link" />
                            <Button disabled={loading} type="submit" fullWidth sx={{ mt: 2 }} color="success" variant="contained" size="large">SALVAR</Button>
                        </CardContent>
                    </Card>
                </form>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        mb: 2, width: {
                            xs: "90%",
                            sm: "90%",
                            md: 500,
                            lg: 500
                        }
                    }}
                >
                    <Button onClick={voltar} sx={{ mb: 3 }} fullWidth startIcon={<ArrowBackIcon />} color="error" variant="contained" size="large">Voltar</Button>
                </Stack>
            </Stack>
        </Box>
    );
};