import { useQuery } from "react-query";
import api from "../../service/api";

async function getUsuarios(ctx) {
  const [, page ] = ctx.queryKey
  const { data } = await api.get('/user', { params: { page } })
  return data
}

export default function useFetchGetUsuarios(page) {
    return useQuery(['user', page], getUsuarios, { refetchOnWindowFocus: false})
}