import { Box, LinearProgress, Stack } from "@mui/material";
import logo from "../assets/logo.png";

export const PageLoading = () => {
  return (
    <Box height="100vh" width="100vw" sx={{ background: "#D14343" }}>
      <LinearProgress color="warning" />
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        height="90%"
      >
        <Box>
          <img
            alt="logo"
            src={logo}
            style={{
              display: "inline-block",
              width: 200,
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
};
