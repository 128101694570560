import { useQuery } from "react-query";
import api from "../../service/api";

async function getAula(ctx) {
  const [, moduloID ] = ctx.queryKey
  const { data } = await api.get('/aula', { params: { moduloID } })
  return data
}

export default function useFetchGetAula(moduloID ) {
    return useQuery(['aula', moduloID ], getAula, { refetchOnWindowFocus: false})
}