import { Box, TextField, Typography } from "@mui/material";

export const Input = ({ label, id, formik }) => {
  return (
    <Box sx={{ mb: 1 }}>
      <Typography sx={{mb: 0.5}} color="text.primary" variant="body2">
        {label}
      </Typography>
      <TextField
        name={id}
        value={formik.values[id]}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        fullWidth
        variant="outlined"
        error={Boolean(formik.touched[id] && formik.errors[id])}
        helperText={formik.touched[id] && formik.errors[id]}
        color="btn"
      />
    </Box>
  );
};
