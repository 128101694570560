import { useQuery } from "react-query";
import api from "../../service/api";

async function getModulo(ctx) {
  const [, cursoID ] = ctx.queryKey
  const { data } = await api.get('/modulo', { params: { cursoID } })
  return data
}

export default function useFetchGetModulo(cursoID ) {
    return useQuery(['modulo', cursoID ], getModulo, { refetchOnWindowFocus: false})
}