import { Alert, Avatar, Box, Button, Collapse, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery } from "@mui/material";
import { NavBar } from "../components/NavBar";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from "react-router-dom";
import useFetchGetUsuarios from "../queries/usuarios";
import { Loading } from "../components/Loading";
import React from "react";
import { SelectMatricula } from "../components/SelectMatricula";
import useFetchGetNota from "../queries/nota";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const Nota = () => {
    const { id } = useParams()
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    let navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    }

    const { isLoading, data } = useFetchGetNota(id)

    return (
        <Box>
            <NavBar />
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                <Typography sx={{ mb: 0.5, mt: 3 }} color="text.primary" variant="button">
                    Notas: <strong>{data && data.nome}</strong>
                </Typography>
                <Loading isLoading={isLoading} />
                {data && <TableContainer sx={{ mt: 1, maxWidth: isSmallScreen ? 350 : 600 }} component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Curso</TableCell>
                                <TableCell>Questionário</TableCell>
                                <TableCell>Módulo</TableCell>
                                <TableCell>Nota</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.notas.map(current => (
                                <TableRow key={current._id}>
                                    <TableCell>{current.questionarioID.moduloID.cursoID.nome}</TableCell>
                                    <TableCell>{current.questionarioID.nome}</TableCell>
                                    <TableCell>{current.questionarioID.moduloID.nome}</TableCell>
                                    <TableCell>{current.nota}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        mb: 2, width: {
                            xs: "90%",
                            sm: "90%",
                            md: 600,
                            lg: 600
                        }
                    }}
                >
                    <Button startIcon={<ArrowBackIcon />} onClick={goBack} fullWidth color="error" variant="contained" size="large">Voltar</Button>
                </Stack>
            </Stack>
        </Box>
    );
}
