import React from "react";
import { Box, CircularProgress } from "@mui/material";

export const Loading = ({ isLoading }) => {
  return (
    <React.Fragment>
      {isLoading && (
        <Box
          sx={{ my: 3 }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress color="primary" size={50} />
        </Box>
      )}
    </React.Fragment>
  );
};
