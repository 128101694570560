import * as React from "react";
import Popover from "@mui/material/Popover";
import {
  Chip,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export default function PopoverInfoNaoassistida({ naoassistida }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Chip
        size="small"
        icon={<VisibilityOffIcon />}
        label={naoassistida ? naoassistida.naoassistida.length : 0}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <List dense={false}>
          {naoassistida && naoassistida.naoassistida.map((current) => (
            <ListItem key={current}>
              <ListItemText
                secondary={current.nome}
              />
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
}
