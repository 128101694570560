import * as React from "react";
import { NavBar } from "../components/NavBar";
import { Box, Button, Card, Chip, Grid, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Stack, Typography } from "@mui/material";
import { Navegacao } from "../components/Navegacao";
import { Title } from "../components/Title";
import YouTube from 'react-youtube';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { useNavigate, useParams } from "react-router-dom";
import useFetchGetVideo from "../queries/video";
import { Loading } from "../components/Loading";
import { NoData } from "../components/NoData";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import usePlataforma from "../hooks/usePlataforma";
import useFetchGetInfoVideo from "../queries/infoVideo";
import PopoverInfoAssistindo from "../components/PopoverInfoAssistindo";
import PopoverInfoAssistido from "../components/PopoverInfoAssistido";
import useStore from "../providers/useStore";
import PopoverInfoNaoassistida from "../components/PopoverInfoNaoassistida";

const opts = {
  height: '360',
  width: '100%',
  playerVars: {
    autoplay: 1,
    modestbranding: 1,
    rel: 0,
    showinfo: 0
  },
};

export const VerAula = () => {
  const infoToken = useStore((state) => state.infoToken)
   const [duration, setDuration] = React.useState(0);
  const { marcaVisto, marcaProgress } = usePlataforma()
  const { id } = useParams()
  let navigate = useNavigate();

  const voltar = () => {
    navigate("/");
  }

  function percentWatched(currentTime, duration) {
    let percent = (currentTime / duration) * 100;
    return Math.round(percent);
  }
  
  
  const onReady = (event) => {
    setDuration(event.target.getDuration());
  };

  const onStateChange = (event) => {
    var time = event.target.getCurrentTime()
    var progress = percentWatched(time, duration)
    if(progress > 0){
      marcaProgress(id, progress)
    }
  };

  const { isLoading, data } = useFetchGetVideo(id)


  const onEnd = (event) => {
    marcaVisto(id)
    marcaProgress(id, 99)
  }

  const verAula = (id) => {
    navigate(`/video/${id}`);
  }

  const { data: infoAssistindo} = useFetchGetInfoVideo(id, "progress")
  const { data: infoAssistida} = useFetchGetInfoVideo(id, "assistida")
  const { data: naoassistida} = useFetchGetInfoVideo(id, "naoassistida")

  return (
    <Box>
      <NavBar />
      <Navegacao voltar="Vídeos" voltarTo={data && data.video.link ? `/videos/${data.video.moduloID}` : "/"} atual="Vídeo" />
      {data && !data.video.link && <Box sx={{ml: 2, mt: 2}}  > <Button size="small" onClick={voltar} sx={{mb: 3}} startIcon={<ArrowBackIcon />} color="error" variant="contained" >VER CURSOS</Button></Box> }
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ mt: 2, px: 3 }}
      >
      {data && <Title title="Vídeo:" strong={data ? data.video.nome : ""} />}
      </Stack>
      { infoToken.isAdmin &&
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
        sx={{ml: 3, mt: 1}}
      >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Typography noWrap component="div">Assistindo</Typography>
        <PopoverInfoAssistindo infoAssistindo={infoAssistindo} />
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Typography noWrap component="div">Visualizado</Typography>
        < PopoverInfoAssistido infoAssistida={infoAssistida} />
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Typography noWrap component="div">Não visto</Typography>
        < PopoverInfoNaoassistida naoassistida={naoassistida} />
      </Stack>
      </Stack> }
      <Loading isLoading={isLoading} />
      <NoData isEmpty={!isLoading && data && data.playlist.length === 0} />
      {!isLoading && data && data.playlist.length > 0 && <Grid container spacing={3} sx={{ p: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
          <Card sx={{ height: 360 }}>
            {!isLoading && data && data.video.link && <YouTube onEnd={onEnd} videoId={data.video.link} opts={opts} onReady={onReady} onStateChange={onStateChange}/>}
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          {!isLoading && data && <List
            sx={{ width: '100%', maxHeight: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Playlist
              </ListSubheader>
            }
          >
            {data.playlist.map(current => (
              <ListItemButton
                key={current._id}
                sx={{ "&.Mui-selected": { backgroundColor: "#D14343", color: "#fff" } }}
                selected={id === current._id}
                onClick={() => verAula(current._id)}
              >
                <ListItemIcon>
                 {id === current._id ? <PlayCircleFilledWhiteIcon sx={{ color: "#fff" }} /> : <PlayCircleOutlineIcon /> } 
                </ListItemIcon>
                <ListItemText primary={current.nome} />
                {current.assistida && <Chip label="Assistido" color="warning" variant="outlined" />}
              </ListItemButton>))}
          </List>}
        </Grid>
      </Grid> }
    </Box>
  );
};
