import { Breadcrumbs, Button, Stack, Typography } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from "react-router-dom";


export const Navegacao = ({ voltar, voltarTo, atual }) => {
    let navigate = useNavigate();

    const irPara = () => {
        if(voltarTo === "-1") {
            navigate(-1);
        } else {
            navigate(voltarTo);
        }
      }

    return (
        <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ px: 3, height: 62, borderBottom: 1, borderColor: 'rgb(55 65 81 / 27%)' }}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                <Button sx={{mr: -1}} onClick={irPara} variant="text">{voltar}</Button>
                <Typography color="text.primary"><strong>{atual}</strong></Typography>
            </Breadcrumbs>
        </Stack>
    );
}
