
import * as React from 'react';
import { NavBar } from '../components/NavBar';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Grid, Stack, Typography } from '@mui/material';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import capa from "../assets/capa.png"
import { Navegacao } from '../components/Navegacao';
import { Title } from '../components/Title';
import { useNavigate, useParams } from 'react-router-dom';
import useFetchGetModulo from '../queries/modulo';
import { Loading } from '../components/Loading';
import { NoData } from '../components/NoData';
import useStore from '../providers/useStore';
import AddIcon from '@mui/icons-material/Add';
import usePlataforma from '../hooks/usePlataforma';

export const Modulos = () => {
  const { apagarModulo } = usePlataforma()
  const infoToken = useStore((state) => state.infoToken)
  const { id } = useParams()
  let navigate = useNavigate();

  const { isLoading, data } = useFetchGetModulo(id)


  const goVideos = (id) => {
    navigate(`/videos/${id}`);
  }

  const novoModulo = () => {
    navigate("/adicionar/modulo", { state: { cursoID: id }});
  }

  return (
    <Box>
      <NavBar />
      <Navegacao voltar="Cursos" voltarTo="/" atual="Módulos"/>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ mt: 2, px: 3 }}
      >

      <Title title="Lista de" strong="Módulos"/>
      { infoToken.isAdmin && <Button onClick={novoModulo} variant="outlined" startIcon={<AddIcon />}> Novo </Button> }
      </Stack>
      <Loading isLoading={isLoading} />
      <NoData isEmpty={!isLoading && data && data.modulos.length === 0} />
      <Grid container spacing={3} sx={{p: 2}}>
      {data && data.modulos.length > 0 && data.modulos.map(current => (
        <Grid key={current._id} item xl={3} lg={3} sm={4} xs={12}>
        <Card sx={{ maxWidth: 345 }}>
        <CardMedia
          component="img"
          alt="capa"
          height="140"
          image={capa}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
           {current.nome}
          </Typography>
          <Typography variant="body2" color="text.secondary">
          {current.descricao}
          </Typography>
        </CardContent>
        <CardActions sx={{ flexDirection: 'column' }}>
        <Button onClick={() => goVideos(current._id)} endIcon={<VideoLibraryIcon />} fullWidth variant='contained' color='error' size="medium">VER AULAS</Button>
        { infoToken.isAdmin && <Button sx={{mt: 1}} onClick={() => apagarModulo(current._id)} fullWidth variant='text' color='error' size="small">Apagar</Button> }
      </CardActions>
      </Card>
        </Grid>
      ))}
      </Grid>
    </Box>
  );
}
