import { Alert, Avatar, Box, Button, Collapse, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery } from "@mui/material";
import { NavBar } from "../components/NavBar";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import useFetchGetUsuarios from "../queries/usuarios";
import { Loading } from "../components/Loading";
import React from "react";
import { SelectMatricula } from "../components/SelectMatricula";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import usePlataforma from "../hooks/usePlataforma";

export const Usuarios = () => {
    const { apagarUser } = usePlataforma()
    const [open, setOpen] = React.useState(false);
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    let navigate = useNavigate();

    const criar = () => {
        navigate("/adicionar/usuario");
    }

    const verNota = (id) => {
        navigate(`/nota/${id}`);
    }

    const handleApagarUser = (id) => {
        apagarUser(id);
    }

    const [page, setPage] = React.useState(1);
    const { isLoading, data } = useFetchGetUsuarios(page)

    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <Box>
            <NavBar />
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                <Typography sx={{ mb: 0.5, mt: 2 }} color="text.primary" variant="button">
                    Usuários
                </Typography>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        mb: 1, width: {
                            xs: "90%",
                            sm: "90%",
                            md: 600,
                            lg: 700
                        }
                    }}
                >
                    <Button onClick={criar} fullWidth startIcon={<AddIcon />} color="success" variant="contained" size="large">Novo</Button>
                </Stack>
                <Loading isLoading={isLoading} />
                {data && <TableContainer sx={{ mt: 1, maxWidth: isSmallScreen ? 350 : 700}} component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nome</TableCell>
                                <TableCell >Email</TableCell>
                                <TableCell align="center">AÇÕES</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.users.map(current => (
                                <React.Fragment key={current._id}>
                                    <TableRow>
                                        <TableCell>{current.nome}</TableCell>
                                        <TableCell >{current.email}</TableCell>
                                        <TableCell align="right">
                                        <Stack
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={1}
                                            >
                                        <Stack
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={1}
                                            sx={{width: 95}}
                                            >
                                            <Button fullWidth onClick={() => setOpen(open === current._id ? null : current._id)} color="btn" variant="outlined" size="small">Matricular</Button>
                                            </Stack>
                                        <Stack
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={1}
                                            sx={{width: 95}}
                                            >
                                            <Button fullWidth onClick={() => verNota(current._id)} color="btn" variant="outlined" size="small">Notas</Button>
                                            </Stack>
                                            <IconButton disabled={current.nome == "Leo"} onClick={() => handleApagarUser(current._id)} aria-label="delete">
                                            <DeleteIcon />
                                            </IconButton>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {open === current._id && (
                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                <Collapse in={true} timeout="auto" unmountOnExit>
                                                    <Box sx={{ margin: 2 }}>
                                                        <SelectMatricula userID={current._id} matriculado={current.matriculado} todosCursos={data.todosCursos} setOpen={setOpen} close={open === current._id ? null : current._id}/>
                                                    </Box>
                                                </Collapse>
                                            </TableCell>)}
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
               {!isLoading && <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        mb: 2, width: {
                            xs: "90%",
                            sm: "90%",
                            md: 600,
                            lg: 700
                        }
                    }}
                >
                    <Pagination count={data ? data.totalPage : 1} page={page} onChange={handleChange} />
                </Stack>}
                <Box sx={{mt: 2}}/>
            </Stack>
        </Box>
    );
}
