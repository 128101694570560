import { useQuery } from "react-query";
import api from "../../service/api";

async function getNota(ctx) {
  const [, userID] = ctx.queryKey
  const { data } = await api.get('/nota', { params: { userID } })
  return data
}

export default function useFetchGetNota(userID) {
    return useQuery(['nota', userID], getNota, { refetchOnWindowFocus: false})
}