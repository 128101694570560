
import * as React from 'react';
import { Alert, AlertTitle, Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { NavBar } from '../components/NavBar';
import { useNavigate, useParams } from 'react-router-dom';
import useStore from '../providers/useStore';
import { Navegacao } from '../components/Navegacao';
import AddIcon from '@mui/icons-material/Add';
import { Title } from '../components/Title';
import { Loading } from '../components/Loading';
import useFetchGetPerguntas from '../queries/perguntas';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import usePlataforma from '../hooks/usePlataforma';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ModalNovaPergunta from '../components/ModalNovaPergunta';

export const VerQuiz = () => {
  const [open, setOpen] = React.useState(false);
  let navigate = useNavigate();
  const { questionarioResponder, publicarQuestionario, apagarPergunta } = usePlataforma()
  const [loading, setLoading] = React.useState(false);
  const infoToken = useStore((state) => state.infoToken)
  const { id } = useParams()
  
  const { data, isLoading } = useFetchGetPerguntas(id)

  function adicionarID() {
    data.perguntas.forEach((item, index) => {
      formik.setFieldValue(`perguntaID${index}`, item._id);
    });
  } 

  const formik = useFormik({
    initialValues: {
      resposta0: "",
      resposta1: "",
      resposta2: "",
      resposta3: "",
      resposta4: "",
      resposta5: "",
      resposta6: "",
      resposta7: "",
      resposta8: "",
      resposta9: "",
      perguntaID0: "",
      perguntaID1: "",
      perguntaID2: "",
      perguntaID3: "",
      perguntaID4: "",
      perguntaID5: "",
      perguntaID6: "",
      perguntaID7: "",
      perguntaID8: "",
      perguntaID9: "",
    },
    validationSchema: Yup.object({

    }),
    onSubmit: async (values) => {
        setLoading(true)
        await questionarioResponder(id, values)
        const idLoading = toast.loading("Carregando...");
        try {
            setLoading(false)
            toast.dismiss(idLoading)

        } catch (error) {
            setLoading(false)
            toast.dismiss(idLoading)
        }
    },
});

function voltar() {
  navigate(-1);
}

  return (
    <Box>
      <ModalNovaPergunta questionarioID={id} open={open} setOpen={setOpen} />
      <NavBar />
      <Navegacao voltar="Módulo" voltarTo="-1" atual="Questionário"/>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ mt: 2, px: 3 }}
      >
      <Title title="Questionário -" strong={data ? data.nome : ""}/>
      { infoToken.isAdmin &&
      <Stack
      direction="row"
      alignItems="center"
      spacing={2}
    > 
   {data && !data.publicado && <Button onClick={() => setOpen(true)}  variant="outlined" startIcon={<AddIcon />}> NOVA PERGUNTA </Button> }
   {data && !data.publicado && <Button onClick={() => publicarQuestionario(id)}  variant="contained" color="success" startIcon={<AddIcon />}> PUBLICAR </Button> }
    </Stack> }
      </Stack>
      <Loading isLoading={isLoading} />
      {data && data.respondido && <Box sx={{ px: 3, mt: 5, mb: 2 }}>
        <Alert severity="success">
          <AlertTitle>Questionário respondido!</AlertTitle>
          Sua nota foi: <strong>{data.nota}.</strong>
        </Alert>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{mt: 2}}
        >
          <Button onClick={voltar} sx={{ mb: 3 }} fullWidth startIcon={<ArrowBackIcon />} color="error" variant="contained" size="large">Voltar</Button>
        </Stack>
      </Box>}
      <Stack sx={{px:3, mt:5, mb: 2}} direction="column" >
      {data && !data.publicado && data.perguntas.length == 0 && infoToken.isAdmin && <Alert severity="warning">Adicione novas perguntas e, em seguida, publique o questionário para os alunos responderem.</Alert>}
      </Stack>
      <form onSubmit={(event) => {
        event.preventDefault();
        adicionarID()
        formik.handleSubmit()
      }}>
     {data && !data.respondido &&  <Stack
      direction="column"
      spacing={2}
      sx={{px:3, mt:5, mb: 2}}>
        {data && data.perguntas.map((current, index) => (
          <FormControl key={index} sx={{mb: 2}}>
          <FormLabel  id={`resposta${index}`}>{current.pergunta} { data && !data.publicado && infoToken.isAdmin && <Button onClick={() => apagarPergunta(current._id)} sx={{ml: 4}}>Apagar pergunta</Button> } </FormLabel>
          <RadioGroup
            aria-labelledby={`resposta${index}`}
            name={`resposta${index}`}
            value={formik.values[`resposta${index}`]}
            onChange={formik.handleChange}
          >
            <FormControlLabel value={current.respostas[0]} control={<Radio />} label={current.respostas[0]} />
            <FormControlLabel value={current.respostas[1]} control={<Radio />} label={current.respostas[1]} />
            <FormControlLabel value={current.respostas[2]} control={<Radio />} label={current.respostas[2]} />
          </RadioGroup>
        </FormControl>
        ))}
       { !infoToken.isAdmin && <Button disabled={loading} type="submit" fullWidth sx={{ mt: 2 }} color="success" variant="contained" size="large">RESPONDER</Button> }
      </Stack> }
      </form>
    </Box>
  );
}
