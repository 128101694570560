import { useQuery } from "react-query";
import api from "../../service/api";

async function getQuestionario(ctx) {
  const [, moduloID ] = ctx.queryKey
  const { data } = await api.get('/questionario', { params: { moduloID } })
  return data
}

export default function useFetchGetQuestionario(moduloID) {
    return useQuery(['questionario', moduloID ], getQuestionario, { refetchOnWindowFocus: false})
}