import api from "../service/api"
import { toast } from "react-toastify"
import { useQueryClient } from "react-query";

const usePlataforma = () => {
    const queryClient = useQueryClient();

    const marcaVisto = async (aulaID) => {
        try {
            await api.post("/assistida", { aulaID })
        } catch (error) {
            console.log(error)
        }
    }

    const marcaProgress = async (aulaID, progress) => {
        try {
            await api.post("/video/progress", { aulaID, progress })
        } catch (error) {
            console.log(error)
        }
    }

    const saveCurso = async (values) => {
        try {
            await api.post("/curso", values)
        } catch (error) {
            console.log(error)
        }
    }

    const saveModulo = async (values) => {
        try {
            await api.post("/modulo", values)
        } catch (error) {
            console.log(error)
        }
    }

    const saveAula = async (values) => {
        try {
            await api.post("/aula", values)
        } catch (error) {
            console.log(error)
        }
    }

    const saveQuiz = async (values) => {
        try {
            await api.post("/questionario", values)
        } catch (error) {
            console.log(error)
        }
    }

    const saveAluno = async (values) => {
        try {
            await api.post("/user", values)
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }

    const matricularAluno = async (userID, cursos) => {
        try {
            await api.post("/matricula", { userID, cursos })
            queryClient.invalidateQueries(['user'])
        } catch (error) {
            console.log(error)
        }
    }

    const questionarioResponder = async (questionarioID, values) => {
        try {
            await api.post("/questionario/responder", { questionarioID, values })
            queryClient.invalidateQueries(['pergunta'])
        } catch (error) {
            console.log(error)
            toast.error(error.response.data.message)
        }
    }

    const publicarQuestionario = async (questionarioID) => {
        try {
            await api.post("/questionario/publicar", { questionarioID })
            queryClient.invalidateQueries(['pergunta'])
        } catch (error) {
            console.log(error)
            toast.error(error.response.data.message)
        }
    }

    const novaPergunta = async (pergunta, questionarioID, respostas, respostaCorreta) => {
        try {
            await api.post("pergunta", { pergunta, questionarioID, respostas, respostaCorreta })
            queryClient.invalidateQueries(['pergunta'])
        } catch (error) {
            console.log(error)
            toast.error(error.response.data.message)
        }
    }

    const apagarPergunta = async (id) => {
        try {
            await api.delete(`/pergunta/${id}`)
            queryClient.invalidateQueries(['pergunta'])
        } catch (error) {
            console.log(error)
            toast.error(error.response.data.message)
        }
    }

    const apagarCurso = async (id) => {
        try {
            await api.delete(`/curso/${id}`)
            queryClient.invalidateQueries(['curso'])
        } catch (error) {
            console.log(error)
            toast.error(error.response.data.message)
        }
    }

    const apagarModulo = async (id) => {
        try {
            await api.delete(`/modulo/${id}`)
            queryClient.invalidateQueries(['modulo'])
        } catch (error) {
            console.log(error)
            toast.error(error.response.data.message)
        }
    }

    const apagarAula = async (id) => {
        try {
            await api.delete(`/aula/${id}`)
            queryClient.invalidateQueries(['aula'])
        } catch (error) {
            console.log(error)
            toast.error(error.response.data.message)
        }
    }

    const apagarQuestionario = async (id) => {
        try {
            await api.delete(`/questionario/${id}`)
            queryClient.invalidateQueries(['questionario'])
        } catch (error) {
            console.log(error)
            toast.error(error.response.data.message)
        }
    }

    const apagarUser = async (id) => {
        try {
            await api.delete(`/user/${id}`)
            queryClient.invalidateQueries(['user'])
        } catch (error) {
            console.log(error)
            toast.error(error.response.data.message)
        }
    }

    
    return {
      marcaVisto,
      marcaProgress,
      saveCurso,
      saveModulo,
      saveAula,
      saveAluno,
      matricularAluno,
      questionarioResponder,
      publicarQuestionario,
      novaPergunta,
      saveQuiz,
      apagarPergunta,
      apagarCurso,
      apagarModulo,
      apagarAula,
      apagarQuestionario,
      apagarUser
    };
}

export default usePlataforma;
