import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Backdrop, CircularProgress, Stack, TextField } from '@mui/material';
import usePlataforma from '../hooks/usePlataforma';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ModalNovaPergunta({ setOpen, open, questionarioID }) {
    const { novaPergunta } = usePlataforma()
    const [pergunta, setPergunta] = React.useState("");
    const [respostaCorreta, setRespostaCorreta] = React.useState("");
    const [resposta1, setResposta1] = React.useState("");
    const [resposta2, setResposta2] = React.useState("");
    const [resposta3, setResposta3] = React.useState("");
    const [carregando, setCarregando] = React.useState(false);
 
  const handleClose = () => {
    setOpen(false);
    setPergunta("")
    setRespostaCorreta("")
    setResposta1("")
    setResposta2("")
    setResposta3("")
  };

  const podeEnviar = !!pergunta && !!respostaCorreta && !!resposta1 && !!resposta2 && !!resposta3

  const salvar = async () => {
    setCarregando(true)
    await novaPergunta(pergunta, questionarioID, [resposta1, resposta2, resposta3], respostaCorreta)
    setCarregando(false)
    handleClose()
  }

  return (
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Nova Pergunta
        </BootstrapDialogTitle>
        <DialogContent dividers>
        <Typography gutterBottom>
            Pergunta
          </Typography>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{mt: 1, mb: 2}} 
            >
          <TextField value={pergunta} onChange={event => setPergunta(event.target.value)} sx={{width: 500}} size='small' placeholder='Escreva aqui...' fullWidth variant="outlined" />
          </Stack>
          <Typography gutterBottom>
            Opção de resposta 1
          </Typography>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{mt: 1, mb: 2}} 
            >
          <TextField value={resposta1} onChange={event => setResposta1(event.target.value)} size='small' placeholder='Escreva aqui...' fullWidth variant="outlined" />
          <Button variant='contained' color="success" autoFocus onClick={() => setRespostaCorreta(resposta1)}>
            Correta
          </Button>
          </Stack>
          <Typography gutterBottom>
            Opção de resposta 2
          </Typography>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{mt: 1, mb: 2}} 
            >
          <TextField value={resposta2} onChange={event => setResposta2(event.target.value)} size='small' placeholder='Escreva aqui...' fullWidth variant="outlined" />
          <Button variant='contained' color="success" autoFocus onClick={() => setRespostaCorreta(resposta2)}>
            Correta
          </Button>
          </Stack>
          <Typography gutterBottom>
            Opção de resposta 3
          </Typography>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{mt: 1, mb: 2}} 
            >
          <TextField value={resposta3} onChange={event => setResposta3(event.target.value)} size='small' placeholder='Escreva aqui...' fullWidth variant="outlined" />
          <Button variant='contained' color="success" autoFocus onClick={() => setRespostaCorreta(resposta3)}>
            Correta
          </Button>
          </Stack>
          {Boolean(respostaCorreta) &&  <Typography gutterBottom>
           Resposta certa escolhida: <strong>{respostaCorreta}</strong>
          </Typography> } 
        </DialogContent>
        <DialogActions>
          <Button disabled={!podeEnviar} variant='contained' color="success" autoFocus onClick={salvar}>
            Salvar
          </Button>
        </DialogActions>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={carregando}
        >
        <CircularProgress color="inherit" />
        </Backdrop>
      </BootstrapDialog>
  );
}
