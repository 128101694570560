import { useQuery } from "react-query";
import api from "../../service/api";

async function getVideo(ctx) {
  const [, id ] = ctx.queryKey
  const { data } = await api.get('/video', { params: { id } })
  return data
}

export default function useFetchGetVideo(id) {
    return useQuery(['video', id], getVideo, { refetchOnWindowFocus: false})
}