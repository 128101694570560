import { useQuery } from "react-query";
import api from "../../service/api";

async function getPerguntas(ctx) {
  const [, questionarioID ] = ctx.queryKey
  const { data } = await api.get('/pergunta', { params: { questionarioID } })
  return data
}

export default function useFetchGetPerguntas(questionarioID) {
    return useQuery(['pergunta', questionarioID ], getPerguntas, { refetchOnWindowFocus: false})
}